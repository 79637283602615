.cards-box-data {
    border: 1px solid #E2E8F0;
    border-radius: 0.375rem;
    padding: 1rem;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.NewheadingFx{
    font-Size: '1.125rem';
    font-Weight: '500';
    margin-Bottom: '0.5rem';
}
.pra{
    font-Size: '0.875rem';
    margin-Bottom: '1.5rem';
}




/* Main container styles */

  .segment-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.5rem;
    // spacing: 1.5rem;
  }
  
  /* Header card styles */
  .segment-header {
    background-color: white;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    margin-top: -2%;
  }
  
  .segment-header:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .channel-info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .channel-icon {
    position: relative;
    width: 3rem;
    height: 3rem;
    background-color: #F9E0A3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .channel-icon-inner {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 2px solid rgb(244, 67, 54);
    margin: 6px;
  }
  
  .info-label {
    font-size: 0.875rem;
    color: #4A5568;
  }
  
  .info-value {
    font-weight: 500;
  }
  
  /* Segment rules section */
  .segment-rules {
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    margin-top: 10px;
    background-color: white;
  }
  
  .rules-title {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: #4A5568;
  }
  
  .rules-description {
    font-size: 0.875rem;
    color: #A0AEC0;
    margin-bottom: 1.5rem;
  }
  
  /* Rule card styles */
  .rule-card {
    width: 100%;
    max-width: 1200px;
    padding: 1.5rem;
  }
  
  .rule-card-inner {
    border: 1px solid #E2E8F0;
    border-radius: 0.375rem;
    padding: 1rem;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .rule-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .rule-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .rule-icon {
    color: #4A5568;
  }
  
  .rule-label {
    font-size: 0.875rem;
    color: #4A5568;
  }
  
  .rule-limit {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.875rem;
    color: #A0AEC0;
  }
  
  .chevron-icon {
    width: 1rem;
    height: 1rem;
    color: #A0AEC0;
  }
  
  .rule-content {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .rule-select {
    flex: 1;
    color: #4A5568;
  }
  
  .equals-text {
    font-size: 0.875rem;
    color: #A0AEC0;
  }
  
  .add-rule-button {
    padding: 0.5rem;
    border-radius: 9999px;
    background-color: #4C51BF;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .add-rule-icon {
    width: 1rem;
    height: 1rem;
    color: #FFFFFF;
  }
  
  /* Antd Select component override */
  .ant-select {
    flex: 1;
  }
  
  .ant-select-selector {
    border-color: #E2E8F0 !important;
  }


  .rule-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .rule-content.collapsed {
    max-height: 500px; 
  }
  
  .chevron-icon {
    transition: transform 0.3s ease;
  }
  
  .chevron-icon.rotated {
    transform: rotate(180deg);
  }
  