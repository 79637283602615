.main-team-defualt{
    margin-top: 2%;
    width: 99%;
    height: 15%;
    border: 1px solid #e2dada;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.main-team-defualt:hover{
    background-color: #f8f6f6;
}
.my-team-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;
}


.my-team-text{
    font-size: 20px;
    opacity: .7;
    font-weight: 550;
}
.my-team-flex-part{
    display: flex;
    gap: 13px;
    align-items: center;
    margin-top: 6%;
}
.my-deafult{
    width: 57px;
    border: 1px solid rgb(218, 213, 213);
    border-radius: 4px;
    text-align: center;
    background-color: #dfdada;
    opacity: .8;
}
.my-member-flex-part{
    display: flex;
    gap: 10px;
    margin-top: 2%;
    margin-left: 1%
}
.main-team-flex-part{
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    margin-top: 2%;
}
.main-container-fuid{
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 767px){
    .my-team-text {
        font-size: 14px;
        opacity: 0.7;
        font-weight: 550;
    }
    .my-team-container{
        .css-sghohy-MuiButtonBase-root-MuiButton-root{
            width: 40% !important;
            height: 10%  !important;
            flex: 0 0 auto !important;
            font-size: 9px !important;
        }  
          
    }
}