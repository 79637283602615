.Clickoniconbelow{
    margin-top: 2%;
    font-size: 15px;
    font-weight: 500;
}
.Appearance{
    margin-top: 2%;
    font-size: 15px;
    font-weight: 600;
}
.ChooseWidgetIcons{
    margin-top: 2%;
    font-size: 15px;
    font-weight: 500;
}
@media screen and (max-width: 767px) {
    .Clickoniconbelow {
        margin-top: 4%;
        font-size: 12px;
        font-weight: 500;
    }
    .Appearance {
        margin-top: 2%;
        font-size: 13px;
        font-weight: 600;
    }
    .ChooseWidgetIcons {
        margin-top: 2%;
        font-size: 14px;
        font-weight: 500;
    }
  }