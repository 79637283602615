.main-access-key{
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
    .main-access-key-text{
        font-size: 20px;
        font-weight: 550;
        opacity: 0.7;
    }
    .AiOutlinePlus{
        margin-right: 6px;
        font-size: 16px;
    }
}
.Development-Doucmentaions{
    margin-top: 2%;
    font-size: 14px;
    color: rgb(37, 99, 235);
    .BiExit{
        color: gray;
        font-size: 20px;
        margin-left: 1%;
    
    }
}

.main-webhooks{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4%;
    .webhook-container{
        display: flex;
        flex-direction: column;
        margin-left: 1%;
    }
   
    .Webhooks-text{
        font-size: 20px;
        font-weight: 550;
        opacity: 0.7;
    }
    .Webhooks-text-p{
        font-size: 14px;
        opacity: 0.7
    }
    .AiOutlinePlus{
        margin-right: 6px;
        font-size: 16px;
    }
}

   
   





@media screen and (max-width: 767px){
    .Development-Doucmentaions{
        margin-top: 2%;
        font-size: 11px;
        color: rgb(37, 99, 235);

        .BiExit {
            color: gray;
            font-size: 15px;
            margin-left: 1%;
        }
        
    }
    .main-access-key{
        .main-access-key-text{
            font-size: 15px;
            font-weight: 550;
            opacity: 0.7;
        }
        .css-sghohy-MuiButtonBase-root-MuiButton-root{
            font-size: 9px;

        }
        .AiOutlinePlus {
            margin-right: 8px;
            font-size: 9px;
        }
    }
    .main-webhooks{
        .Webhooks-text {
            font-size: 15px;
            font-weight: 550;
            opacity: 0.7;
        }
        .Webhooks-text-p {
            font-size: 10px;
            opacity: 0.7;
        }
        .AiOutlinePlus {
            margin-right: 8px;
            font-size: 9px;
        }
        .css-sghohy-MuiButtonBase-root-MuiButton-root{
            font-size: 9px;

        }
    }
}

.main-name-api{
    display: flex;
    flex-direction: column;
    .api-name-input{
      margin-top: 1%;
    }
  }
  .Custom-Api{
    font-size: 25px;
    font-weight: 600; 
  }
  .api-label-name{
    margin-top: 2%;
    font-size: 16px;
    font-weight: 600;
  }
  .error-value{
        color: red;
        margin: 0 auto 0 25%;
      }
  .error{
    color: red;
        left: 9px;
        margin-right: 1%;
  }
 