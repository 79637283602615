.Main-fluid{
    display: flex;
    justify-content: space-between;
}


.main-icon{
    display: flex;
    flex-direction: column;
    margin-top: 4%;
    margin-left: 10%;
}


.main-icon h3{
    font-size: 32px;
    margin-top: 2%;
}
.HiOutlineDocumentText{
    font-size: 54px;
    color: #04c8c8;

}

.main-icon p{
    font-size: 16px;
}
.alert{

    width: 75%;
    margin-top: 5%;
    margin-left: 10%;
}
.menu{
    display: flex;
    gap: 20px;
    margin-left: 4%;
    margin-top: 4%;
}

.main-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.main {
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E3F2FD;
    width: 75%;
    margin-left: 12%;
    margin-top: 4%;
  }

  .alert-title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .button-container {
    display: flex;
    align-items: center;
   
  }
  .main-input{
    display: flex;
    gap: 10px;
    margin-top: 2%;
  }
  

  .custom-button {
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    background-color: #b6c4db;
    border-radius: 5px;
  }
  .custom-button:hover{
    background-color: #798699;

  }


  .infoheading{
    font-size: 12px;
    font-weight: 510;
    color: rgb(0, 168, 107);
  }
  .infodescription{
    font-size: 11px;
    font-weight: 400;
    color: rgb(0, 168, 107);
    opacity: 0.6;
  }
  .intobtn{
    font-size: 12px;
    font-weight: 550;
    color: rgb(0, 168, 107);
  }
  @media screen and (max-width: 767px){
    .infoheading {
      font-size: 10px;
      font-weight: 510;
      color: rgb(0, 168, 107);
  }
  .infodescription {
    font-size: 7px;
    font-weight: 400;
    color: rgb(0, 168, 107);
    opacity: 0.6;
}

.intobtn {
    font-size: 7px;
    font-weight: 550;
    color: rgb(0, 168, 107);
   
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
  font-size: 8px !important;
  min-width: 23px !important;
  padding: 10px 6px !important;
}
.main-menu{
.css-sghohy-MuiButtonBase-root-MuiButton-root {
  flex: 0 0 auto !important;
    font-size: 5px !important;

}

}
:where(.css-dev-only-do-not-override-6j9yrn).ant-table-wrapper .ant-table {
  font-size: 9px !important;
}

  }

.icons-radio-edit-templates {
  font-size: 30px;
  color:grey;
}
.btns-templates {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 30px
}

.btn-templates-container {
  justify-content: space-between;
  display: grid;
  grid-template-columns: 35% 35% 20%;
  margin-bottom: 20px;
  align-items: center;
}