.main-current-plan{
    display: flex;
    flex-direction: column;
    margin-top: 3%;
}
.main-table-plan{
    display: flex;
    gap: 28px;
}
.current-plan-text{
    font-size: 20px;
    font-weight: 550;
    opacity: .7;
}
.grow-p{
    font-size: 16px;
    color: black; 
}


.button-box {
    width: 230px;
    margin: 35px auto;
    position: relative;
    border-radius: 6px;
    background: #dad7d7;
    display: flex;
    justify-content: space-between; 
  }
  
  .toggle-btn {
    padding: 10px 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    outline: none;
    position: relative;
    text-align: center;
    flex: 1; 
  }
  
  .left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  
  .right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  
  #btn {
       left: 0;
    top: 0;
    position: absolute;
    width: 119px; 
    height: 100%;
    background: #fff;
    border-radius: 5px;
    transition: 0.1s;
  }
  .main-whats-include{
    display: flex;
    flex-direction: column;
  }
  .grow{
    font-size: 20px;
  }
  .grow-p{
    font-size: 13px;
    opacity: .7;
  }
  .doller{
    display: flex;
  }
  .doller-text{
    font-size: 34px;
    font-weight: 900;
  }
  .doller-text-p{
    font-size: 21px;
    margin-top: 3%;
    margin-left: 4px;
    opacity: .7;
  }
  .per-every{
    font-size: 16px;
    //margin-left: 10%;
    //margin-top: -1%;
    opacity: .7;
  }
  .main-whats-include-text{
    font-size: 15px;
    margin-top: 1%; /* margin-top: 11%; */
    //margin-left: 12%;
  }
  .main-everyting{
    display: flex;
    gap: 10px;
    margin-top: 2%;
    //margin-left: 10%;
}
  .main-everyting-p{
    font-size: 13px;
    opacity: .7;
  }
  .doller-p{
    font-size: 17px;
    margin-top: 17px;
    opacity: .7;
    margin-left: 2%;
  }
  .isMonthlyBilling{
    margin-left: 23%;
    margin-top: -25px;
    font-size: 15px;
    opacity: 0.7;
  }
  .AiOutlineCheck{
    color: rgb(88, 214, 141);
    font-size: 20px;
  }
  .price-plan-text{
    .css-sghohy-MuiButtonBase-root-MuiButton-root{
      width: 100% !important;
      height: 38px !important;
        }
  }
  @media screen and (max-width: 767px){
    .price-plan-text{
      .grow {
        font-size: 15px;
    }
    .grow-p {
      font-size: 5px;
      opacity: 0.7;
      width: max-content;
  }
  .isMonthlyBilling {
    margin-left: 43%;
    margin-top: -18px;
    font-size: 9px;
    opacity: 0.7;
}
  .doller-text {
    font-size: 15px;
    font-weight: 900;
}
.doller-p {
  font-size: 12px;
    margin-top: 1px;
    opacity: 0.7;
    margin-left: 2%;
}
.per-every {
    font-size: 4px;
    opacity: 0.7;
}
    }
    .main-whats-include{
      .main-whats-include-text {
        font-size: 11px;
        margin-top: 1%;
       
    }
    .main-everyting-p {
      font-size: 8px;
      opacity: 0.7;
  }
  .AiOutlineCheck {
    color: rgb(88, 214, 141);
    font-size: 13px;
}
    }
    .price-plan-text{
      .css-sghohy-MuiButtonBase-root-MuiButton-root{
    width: 100% !important;
    height: 29px !important;
    font-size: 8px;
          }
    }
    .main-current-plan{
      .current-plan-text {
        font-size: 14px;
        font-weight: 550;
        opacity: 0.7;
    }
    }
    .button-box{
      .toggle-btn {
        padding: 10px 0;
        cursor: pointer;
        background: transparent;
        border: 0;
        outline: none;
        position: relative;
        text-align: center;
        flex: 1 1;
        font-size: 8px;
    }
    }
 
  }