
.Bottoagent{
    .bot-agent-whatsapp{
        font-size: 16px;
        font-weight: 600;
        margin-top: 3px;
    }
    .sumit-btn{
        float: right;
        margin-top: 1%
    }
}
@media only screen and (max-width: 767px) {
  .Bottoagent{
    .bot-agent-whatsapp {
        font-size: 12px;
        font-weight: 600;
        margin-top: 7px;
    }
    .sumit-btn{
        float: right;
        margin-top: 3%;
        font-size: 10px;
    }
}

  }