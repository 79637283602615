.left-section-content {
  .mb-lg-20 {
    margin-bottom: 0 !important;
  }
}
.header-portal-first{
  width: 46%;
  background: #363a77;
  background-size: cover;

}
@media screen and (min-width: 200px) and (max-width: 1000px) {
  .header-portal-first{
    display: none;
  }
}
.main-Discription{
  .Discription{
    font-size: 23px;
    margin-top: 1%;
    text-align: center;
    color: white;
  }
}
@media screen and (max-width: 1282px) {
  .main-Discription .Discription {
    font-size: 22px;
  }
}

@media screen and (max-width: 1229px) {
  .main-Discription .Discription {
    font-size: 21px;
  }
}

@media screen and (max-width: 1188px) {
  .main-Discription .Discription {
    font-size: 20px;
  }
}

@media screen and (max-width: 1140px) {
  .main-Discription .Discription {
    font-size: 19px;
  }
}

@media screen and (max-width: 1097px) {
  .main-Discription .Discription {
    font-size: 18px;
  }
}

@media screen and (max-width: 1048px) {
  .main-Discription .Discription {
    font-size: 17px;
  }
}

@media screen and (max-width: 1002px) {
  .main-Discription .Discription {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1004px) {
  .main-Discription .Discription {
    font-size: 16px;
  }
}

@media screen and (max-width: 1037px) {
  .main-Discription .Discription {
    font-size: 18px;
  }
}

@media screen and (max-width: 1032px) {
  .main-Discription .Discription {
    font-size: 17px;
  }
}

@media screen and (max-width: 1042px) {
  .main-Discription .Discription {
    font-size: 17px;
  }
}
@media screen and (max-width: 1190px) {
  .main-Discription .Discription {
    font-size: 17px;
  }
}
@media screen and (max-width: 1283px) {
  .main-Discription .Discription {
    font-size: 20px;
  }
}
@media screen and (max-width: 1001px) {
  .main-Discription .Discription {
    font-size: 16px;
  }
}
@media screen and (max-width: 1002px) {
  .main-Discription .Discription {
    font-size: 16px;
  }
}
@media screen and (max-width: 1004px) {
  .main-Discription .Discription {
    font-size: 16px;
  }
}
@media screen and (max-width: 1132px) {
  .main-Discription .Discription {
    font-size: 19px;
  }
}
@media screen and (max-width: 1083px) {
  .main-Discription .Discription {
    font-size: 18px;
  }
}
@media screen and (max-width: 1092px) and (max-width: 1092px) {
  .main-Discription .Discription {
    font-size: 17px;
  }
}
@media screen and (max-width: 1140px) {
  .main-Discription .Discription {
    font-size: 17px;
  }
}
@media screen and (max-width: 1141px) {
  .main-Discription .Discription {
    font-size: 17px;
  }
}