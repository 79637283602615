.main-container-password{
.Paaswordpolicy{
    display: flex;
    gap: 40px;
    margin-top: 3%;
}
.Paaswordpolicy-label{
    display: flex;
    flex-direction: column;
}
.policy-btn{
    float: right;
    top: 3%;
}
}
@media only screen and (max-width: 767px) {
    .main-container-password{
        margin-top: 2%;
        .Paaswordpolicy{
            display: flex;
            flex-direction: column;
            gap: 19px;
            margin-top: 3%;
        }
        .policy-btn{
            float: right;
            top: 18%;
        }
    }
  
  
    }