.dropdown-log-header{
  padding: 10px;
}
.heading{
  font-size: 20px;
  font-weight: 550;
  opacity: 0.7;
}

.icon {
  transform: rotate(350deg);
}
.Mark-heading{
  opacity: 0.7;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  margin-top: 1%;
}
.Mark-heading:hover{
  text-decoration: underline;
}
.status-older{
  opacity: 0.7;
  font-size: 15px;
  font-weight: 400;
}
.status-notify{
  opacity: 0.7;
  font-size: 15px;
  font-weight: 400;
  margin-top: 1%;
}