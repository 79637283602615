.your-folder-icon{
    display: flex;
    gap: 54px;
    margin-top: 3%;
}
.MoreHorizIcon{
    width: fit-content;
    height: fit-content;
    border: 1px solid #f2f6f9;
    border-radius: 8px;
}
.MoreHorizIcon:hover {
    background-color: rgb(236, 238, 240); 
}
.Your-folder-text{
    font-size: 17px;
    opacity: 0.5;
}
.container-table{
    font-size: 20px;
    opacity: 0.7;
    font-weight: 550;
    margin-top: 2%;
}
@media screen and (max-width: 767px){
.Attribute-container{
    .css-sghohy-MuiButtonBase-root-MuiButton-root{
        font-size: 5px;
        width: max-content;
    }
    .RiContactsFill {
        margin-right: 10px;
        font-size: 8px;
        color: #fff;
    }
    
}
.your-folder-icon{
.Your-folder-text{
    font-size: 14px;
}
.AiOutlineFolderAdd{
    font-size: 20px !important;
}

}

.container-table{
    font-size: 12px;
    opacity: 0.7;
    font-weight: 550;
    margin-top: 2%;
}
}