.Integrations{
    margin-top: 3%;
}
.mainborder-icons-container{
    // height: 200px;
    width: 30%;
    padding: 20px;
    border: 1px solid rgb(231, 222, 222);
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px;
}
.icons-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.prop-icon{
    font-size: 32px;
}
.props-icon2{
    font-size: 26px;
    color: blue;
}
.container-Border{
    display: flex;
    flex-direction: column;
}
.Heading{
    font-size: 17px;
}
.Heading p{
    font-size: 13px;
    width: 75%;
    opacity: .8;
}
@media screen and (max-width: 767px){

    .Integrations{
        .css-sghohy-MuiButtonBase-root-MuiButton-root{
            font-size: 9px;

        }
        .MdHighlightAlt{
            font-size: 15px
        }
    }
.mainborder-icons-container{
    height: 130px;
    width: 32%;
    padding: 9px;
    border: 1px solid rgb(231, 222, 222);
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px;
    .Heading {
        font-size: 12px;
    }
    .Heading-dis{
        font-size: 7px;
       
    }
    .prop-icon {
        font-size: 18px;
    }
    .props-icon2 {
        font-size: 14px;
        color: blue;
    }
}


}