.ChangePassword-container{
    .Passwordcannot{
        margin-top: 2%;
        font-size: 15px;
        font-weight: 600;
    
    }
    .main-continer-input{
        display: flex;
        gap: 2%;
        margin-top: 15px;
        
    }
  .button-save-pass{
    display: flex;
    gap: 5px;
    margin-top: 2%;
  }
}


@media screen and (max-width: 767px) {
    .ChangePassword-container {
    .Passwordcannot {
        margin-top: 4%;
        font-size: 11px;
        font-weight: 600;
       
    }
    :where(.css-dev-only-do-not-override-6j9yrn).ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled){
        width: 50svh !important;
    }
    .button-save-pass{
        display: flex;
        gap: 5px;
        margin-top: 7%;
      }
}
    .main-continer-input{
        display: flex;
        gap: 2%;
        margin-top: 3px !important;
        flex-direction: column !important;
    }

  }