*{
 margin: 0;
 padding: 0;
 font-family: Inter;
}
.cards-box {
//  box-shadow: 0 10px 35px 0 rgba(56, 71, 109, 0.075);
box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
 border-radius: 10px;
 background: white;
 padding: 20px;
}
.synctic-module-def {
 .icon {
  height: 40px;
  width: 40px;
  color: rgb(37, 99, 235)
 }
 .heading {
  font-size: 20px;
  font-weight: 550;
  margin-top: 10px;
  opacity: .7;
 }
 .sub-heading {
  opacity: .7;
 }
}
.synctic-module-container {
 padding: 10px;
 overflow-y: scroll;
 width: 100%;
 height: calc(-5rem + 100vh);
}
.synctic-module-container-tabs {
    background-color: rgb(237 239 241);
    padding: 10px;
    overflow-y: scroll;
    width: 100%;
    height: calc(-5rem + 100vh);
   }
// @media screen and (max-width: 767px) {
//     .cards-box {
//         box-shadow: 0 10px 35px 0 rgba(56, 71, 109, 0.075);
//         border-radius: 10px;
//         background: white;
//         padding: 20px;
//         margin-top: 13%;
//     }
//     .synctic-module-def .heading {
//         font-size: 14px;
//         font-weight: 550;
//         margin-top: 10px;
//         opacity: 0.7;
//     }
//     .synctic-module-def .sub-heading {
//         opacity: 0.7;
//         font-size: 10px;
//     }
//     .synctic-module-def {
//         .icon {
//          height: 20px;
//          width: 20px;
//          color: rgb(37, 99, 235)
//         }
//     }
//   }