.login{
    font-size: 14px;
    font-weight: 600;
    margin-top: 2%;
}
.setting-description{
    margin-top: 4px;
    opacity: 0.7;
}
.FormControlLabel{
.css-j204z7-MuiFormControlLabel-root{
margin-top: -50% !important;
}
}
// .FormControlLabel{
//     margin-top: -50% !important;
// }
.Preference{
    font-size: 20px;
    font-weight: 500;
    margin-top: 2%;

}
.TimeZone{
    font-size: 20px;
    font-weight: 500;
    margin-top: 2%;
}
.Preference-container{
    .submit{
        float: right;
    margin-top: 4%;
    }
}
@media screen and (max-width: 767px) {
    .setting-description{
        margin-top: 4px;
        opacity: 0.7;
        font-size: 10px;
    }
    .FormControlLabel{
        .css-j204z7-MuiFormControlLabel-root{
        margin-top: -124% !important;
        }
        }
        .login {
            font-size: 11px;
            font-weight: 600;
            margin-top: 2%;
        }
        .Preference{
            font-size: 16px;
            font-weight: 500;
            margin-top: 2%;
        
        }
        .TimeZone{
            font-size: 13px;
            font-weight: 500;
            margin-top: 2%;
        }
       /*  .Preference-container .submit {
            float: right;
            margin-top: 14%;
            margin-right: -17%;
        } */
  }