
.main-fluid{
    display: flex;
    flex-direction: column;
}

.bs-icon{
    font-size: 57px;
    color: #04c8c8;
}
.Workspace-div{
    font-size: 39px;
    opacity: .7;
    margin-top: 2%;

}
.Workspace-p{
    font-size: 16px;
    opacity: .7;


}
.workspace-input{
    display: flex;
    flex-direction: column;
    margin-top: 7%;
}

.workspace-input label{
    font-size: 15px;
}

.advance h3{
    font-size: 20px;
    margin-top: 4%;
    opacity: .7;
}
.custom-textfield {
    width: 40%;
    margin-top: 10px;
    
  }
  .workspace-input label{
    margin-top: 10px;
  }
  .workspaceHeader {
      .heading {
          font-size: 20px;
          font-weight: 550;
          margin-top: 10px;
          opacity: .7;
      }
      .heading:hover {
          color: #04c8c8;
      }
      .sub-heading {
          opacity: .7;
      }
  }
  .Workspace-text{
    font-size: 16px;
    font-weight: 550;
    opacity: 0.7;
    margin-top: 4%;
  }
  .export-all{
    font-size: 12px;
    font-weight: 510;
    color: rgb(0, 168, 107);
  }
  .Export-all-conversations{
    font-size: 11px;
    font-weight: 400;
    color: rgb(0, 168, 107);
    opacity: 0.6;
  }
  .read-more-btn{
    font-size: 12px;
    font-weight: 550;
    color: rgb(0, 168, 107);
  }
  @media screen and (max-width: 767px) {
    .workspaceHeader .heading {
        font-size: 14px;
        font-weight: 550;
        margin-top: 10px;
        opacity: 0.7;
    }
    .workspaceHeader .sub-heading {
        opacity: 0.7;
        font-size: 11px;
    }
    .Workspace-text {
        font-size: 13px;
        font-weight: 550;
        opacity: 0.7;
        margin-top: 4%;
    }
    .advance h3 {
        font-size: 13px;
        margin-top: 4%;
        opacity: 0.7;
    }
    .Export-all-conversations {
        font-size: 8px;
        font-weight: 400;
        color: rgb(0, 168, 107);
        opacity: 0.6;
    }
    .read-more-btn{
        font-size: 8px;
        font-weight: 550;
        color: rgb(0, 168, 107);
        flex:  0 0 auto;
      }
      .main-fluid{
        .css-sghohy-MuiButtonBase-root-MuiButton-root{
            font-size: 9px !important;
        }
        
      }
}
.main-name-api{
    display: flex;
    flex-direction: column;
    .api-name-input{
      margin-top: 1%;
    }
  }
  .profile-pic-Data{
    cursor: 'pointer';
    width: '100px';
    height: '100px';
    border: '1px dashed #d9d9d9';
    border-Radius: '8px';
    overflow: 'hidden';
    display: 'flex';
    justify-Content: 'center';
    align-Items: 'center';
    position: 'relative';
  }
  .whatsapp-container-work {
    width: 100%;
    max-width: 1200px;
    padding: 4rem 2rem;
    margin-top: -4%;
    margin-left: auto; 
    margin-right: auto;
}


  .whatsapp-Heading{
    font-size: 1.125rem;
    font-weight: 500;
    // margin-bottom: 0.5rem;
    color: #333; 
    margin: 10px 5px  auto;
}