.main-role-data{
    .role-fulid{
.role{
    font-size: 16px;
    font-weight: 560;
    margin-top: 10px;
    opacity: 0.9;
    font-family: inherit;
}
.discription-hed{
    display: flex;
    gap: 7px;
}
p{
    opacity: .7;
}
span{
    font-weight: 800;
}
}
}
.name{
    margin-bottom: -2px;
    font-size: 20px;
    font-weight: 500;
    opacity: .7;
}

.email{
    margin-bottom: -2px;
    font-size: 15px;
    font-weight: 500;
    opacity: .7;
}

