.main-container-knowledge{
    display: flex;
    flex-direction: column;
    margin-top: 5%;
}
.knowledge-Base{
    font-size: 20px;
    font-weight: 550;
    opacity: .7;
}
.Document{
    font-size: 15px;
    margin-top: 8px;
    opacity: .7;
}

.upload {
    height: 58px;
    width: 100%;
    border: 2px dashed rgb(226, 226, 226);
    border-radius: 5px;
    margin-top: 1%;
    background-color: rgba(0,0,0,.05);
}
.upload:hover{
    border: 2px dashed rgb(122, 119, 119);
    background-color: rgb(235, 235, 235);
}
.pdf-upload-text{
    display: flex;
    gap: 3px;
    opacity: .7;  
}
.main-FaFileUpload-grup{
    display: flex;
    gap: 5px;
    margin-left: 35%;
    margin-top: 17px; 
}
@media screen and (max-width: 767px){
.main-container-knowledge{
    .knowledge-Base {
        font-size: 13px;
        font-weight: 550;
        opacity: 0.7;
    }
    .Document {
        font-size: 11px;
        margin-top: 8px;
        opacity: 0.7;
    }
    .main-FaFileUpload-grup {
        margin-left: 20%;
        font-size: 10px;
    }
}
}