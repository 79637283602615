.reports-main {
  overflow-y: scroll;
  width: 100%;
  height: calc(100vh - 5rem);
}
.reports-heading {
  font-size: 16px;
  font-weight: 510;
  // opacity: .7;
}
.reports-tabs-parent {
  display: flex;
  align-items: center;
  margin-top: 1%;
  .reports-tabs-sub-parent {
    display: flex;
    gap: 20px;
    font-size: 12px;
    .right-elem {
      display: flex;
      align-items: center;
      gap: 10px;
      opacity: .7;
    }
  }
}
.reports-tabs {
  opacity: .7;
  padding: 4px;
  border-radius: 2px;
}
.reports-tabs:hover {
  background: rgb(243, 244, 246)
}
.states-main {
  padding: 20px;
  background: rgb(249, 250, 251);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  .heading {
    font-size: 13px;
    opacity: .7;
    max-width: 90px;
  }
}
.chartsParent {
  display: grid;
  grid-template-columns: 47% 47%;
  justify-content: space-around;
  margin-top: 3%;
}
.charts-sub-parent {
  padding: 30px;
  padding-top: 20px;
  border: 1px solid;
  border-radius: 10px;
  height: 300px;
  border-color: rgba(0,0,0,.4);
  .heading {
    font-size: 14px;
    opacity: .7;
  }
}
.overveiw{
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 3%;
  opacity: 0.7;
}
.main-states-container{
  display: flex;
  gap: 20px;
  margin-top: 10px;
}
.Team-Agents{
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  margin-left: 3%;
  opacity: 0.7;
}
@media screen and (max-width: 767px) {
  .reports-tabs-parent {
    .reports-tabs {
      opacity: .7;
      padding: 4px;
      border-radius: 2px;
      font-size: 8px;
    }
  }
  .reports-heading {
    font-size: 13px;
    font-weight: 510;
    opacity: 0.7;
}
.overveiw {
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 3%;
  opacity: 0.7;
}
.main-states-container{
  display: flex;
  gap: 20px;
  margin-top: 3%;
  flex-direction: column;
}
.states-main 
.heading {
  font-size: 10px;
  opacity: 0.7;
  max-width: 90px;
}
.Team-Agents {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  margin-left: 3%;
  opacity: 0.7;
}
.Team-Agents {
  font-size: 15px;
  font-weight: 600;
  margin-top: 30px;
  margin-left: 3%;
  opacity: 0.7;
}
.chartsParent {
  display: flex;
  justify-content: space-around;
  margin-top: 2%;
  flex-direction: column;
}

}