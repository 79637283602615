.main-customize{
    margin-top: 2%;
}
.customize-text{
    font-size: 20px;
    font-weight: 550;
    opacity: .7;
    margin-top: 3%;
}
.main-customize{
    display: flex;
    flex-direction: column;
}
.Minimal-Layout{
    font-size: 20px;
    opacity: .7;
    font-weight: 550;
    margin-top: 6%;
}
.Minimal-Layout-p{
    font-size: 13px;
    margin-top: 4px;
    opacity: .7; 
}
.main-whatsapp-inputs{
    display: flex;
    gap: 10px;
    margin-top: 5%;
    align-items: center;
    
}
.soylz-text{
    text-align: center;
    font-size: 17px;
    margin-top: 5%;
}
.soylz-text-p{
    text-align: center;
    font-size: 12px;
    opacity: .7;
}
.whts-broder-fluid{
    width: 72%;
    border: 1px solid #c3b8b8;
    border-radius: 8px;
    background-color: #fbfafa;
    margin-left: 16%;
    margin-top: 8%;
    padding: 6px;

}
.twilo-text-wt{
    font-size: 15px;
}
.twilo-text-wt-p{
    font-size: 10px;
    opacity: .7;
}
@media screen and (max-width: 767px){
    .main-customize{
      
        .customize-text {
            font-size: 14px;
            font-weight: 550;
            opacity: 0.7;
            margin-top: 14%;
        }
        .Minimal-Layout {
            font-size: 14px;
            opacity: 0.7;
            font-weight: 550;
            margin-top: 6%;
        }
        .Minimal-Layout-p {
            font-size: 8px;
            margin-top: 4px;
            opacity: 0.7;
        }
        
    }
    .main-whatsapp-inputs{
        .css-sghohy-MuiButtonBase-root-MuiButton-root{
            font-size: 9px !important;
        }
    }
    .btn-container{
        .css-sghohy-MuiButtonBase-root-MuiButton-root {
            width: 63% !important;
              height: 30px !important;
              font-size: 9px;
        }
    }
}
