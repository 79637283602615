.create-templates {
  p {
   margin-top: 0;
    padding-top: 0;
  }
  .category-templates {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    padding:10px;
    border-radius: 8px;
    background: rgba(0,0,0,.05);
  }
  .para {
    opacity: .7;
  }
}