.RiContactsFill{
    margin-right: 10px;
    font-size: 19px;
    color: #fff;
}
.main-table{
    .table{
        font-size: 20px;
        opacity: 0.7;
        font-weight: 550;
        margin-top: 2%;
    }
}
@media screen and (max-width: 767px){
    .Add-Attribute{
        .RiContactsFill{
            font-size: 7px !important; 
        }
        .css-sghohy-MuiButtonBase-root-MuiButton-root{
            font-size: 4px !important;
        }
     
    }
    .main-table{
    .table{
        font-size: 11px;
        opacity: 0.7;
        font-weight: 550;
        margin-top: 2%;
    }
}
}