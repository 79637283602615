.inbox-rules-p{
    opacity: .8;
    text-align: center;
}

.new-rules-hr{
    border: 1px solid #d3caca;
}
.bot-border-rules{
    text-align: center;
    padding: 2px;
    border: 1px solid #f7f7f7;
    background-color: #f5f4f4;
    border-radius: 5px;
}
.bot-border-rules p{
    text-align: center;
    font-weight: 400;
    opacity: .7;
    margin: 4px;
}
.icon {
    transform: rotate(350deg);
}
.Mark-heading{
    opacity: 0.7;
    font-size: 15px;
    margin-top: -9px;
    font-weight: 400;
    cursor: pointer;
}
.Mark-heading:hover{
    text-decoration: underline;
}
.status-notify{
    opacity: 0.7;
    font-size: 15px;
    margin-top: -9px;
    font-weight: 400;
}