.overview-example__add {
  display: none;
}

.react-flow__node a {
  font-weight: 700;
  color: #111;
}

.react-flow__node.dark-node {
  background: #0041d0;
  color: #f8f8f8;
}

.react-flow__node.dark {
  background: #557;
  color: #f8f8f8;
}

.react-flow__node-selectorNode {
  font-size: 12px;
  background: #f0f2f3;
  border: 1px solid 555;
  border-radius: 5px;
  text-align: center;
}

.react-flow__node-selectorNode .react-flow__handle {
  border-color: #f0f2f3;
}


.dndflow {
  flex-direction: row;
  display: flex;
  height: 100vh;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
  width: 30vh;
}

.dndflow aside > * {
  cursor: grab;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.flow-wrapper{
  flex: 1;
}

.flow-setting h3{
  font-size: 16px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.flow-list{
  list-style: none;
  margin: 0;
  padding: 0;
}
.flow-list li{
  min-height: 50px;
  border-radius: 10px;
  background-color: rgb(4, 200, 200);
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 10px;
  color: #ffffff;
  margin-top: 10px;
  
}

.flow-list li img{
  width: 40px;

}

.flow-list li div h5{
  font-size: 12px;
  font-weight: 550;
  padding-top: 6px;
  margin: 0 0 5px;
  text-transform: uppercase;
  color: white;
  
}
.flow-list li div p{
  font-size: 10px;
  color: #ffffff;
  margin: 0;
  
}
.message-editor {
  position: fixed;
  top: 8%;
  right: 0;
  max-width: 280px;
  background-color: #e8e8e8;
  padding: 15px;
  height: calc(100vh - 22%);
  z-index: 1000;
  width: 100%;
  overflow-y: auto;
  border-radius: 10px 0 0 10px;
}
.action-list {
  display: flex;
  align-items: center;
  max-width: 102px;
  width: 100%;
  height: 18px;
  background-color: #000000;
  list-style: none;
  padding: 4px;
  margin: 0 0 5px;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  margin: 0 auto;
  position: absolute;
  top: -26px;
  left: 0;
}
.action-list li{
width: 20px;
height: 20px;
color: #ffffff;
}
.action-list li i{
  font-size: 14px;
  cursor: pointer;
  font-size: 17px;
}

.style-btn{
  width: 100%;
  height: 45px;
  border-radius: 7px;
  background-color: #000000;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}







@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 210px;
  }
}


@media screen and (min-width: 768px) {
  .overview-example__add {
    display: block;
  }
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .dndnode {
  align-items: center;
  border: 1px solid #1a192b;
  border-radius: 2px;
  cursor: grab;
  display: flex;
  height: 20px;
  justify-content: center;
  padding: 4px;
}

.dndflow .dndnode,
.dndflow aside .description {
  margin-bottom: 10px;
}


/* for edges */
.floatingedges {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.floatingedges .react-flow__handle {
  opacity: 0;
}



/* custom node css */
  
.style-text-area{
padding: 7px;
}
.style-text-area textarea{
  width: 100%;
  min-height: 60px;
  overflow-y: auto;
  border: none;
  outline: none;
}

.style-modal-editor {
  position: fixed;
  width: 300px;
  height: 85vh;
  top: 5%;
  right: 0;
  background: #d8d8d8;
  border-radius: 12px;
  padding: 10px;
  overflow-y: auto;
  z-index: 10000;
}

.style-modal-editor textarea{
  width: calc(100% - 22px);
  min-height: 80px;
  padding: 10px;
  resize: none;
  overflow-y: auto;
  border-radius: 7px;
  border: none;
  outline: none;
}

.text-updater-node .style-body {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 0px solid #bbb;
  font-size: 10pt;
}


.text-updater-node .style-title {
  background-color: #10163a;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #fff;
  padding: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.text-updater-node .style-contentWrapper {
  padding: 7px 10px;
  min-height: 95px;
}

.flow-node{
  max-width: 350px;
  width: 100%;
  padding: 10px;
  overflow-wrap: break-word;
  padding: 0;
}

.option-node {
background: #ececec;
height: auto;
width: 100%;
padding: 0;
}
.option-node .style-title {
background: rgb(4, 200, 200);
color: #fff;
  padding: 8px;
  padding-top: 6px;
  padding-bottom: 6px;border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
}
.option-node .style-contentWrapper {
min-height: 70px;
background-color: #fff;
padding: 8px;
border-radius: 5px;
}
.message-inner {
  padding: 8px;
  background: #10163a;
}



.option-node .option-list{
list-style: none;
margin: 0;
padding: 7px;
}
.option-node .option-list li{
min-height: 30px;
font-size: 14px;
background-color: #fff;
display: flex;
align-items: center;
padding: 0 5px;
position: relative;
}



.list-node{
min-height: 30px;
background-color: #ffffff;
padding: 4px;
}


.option-editor li {
position: relative;
}

.option-editor li i{
position: absolute;
top: 15px;
right: 6px;
font-size: 14px;
}

/* handle css */
.option-node-target {
width: 13px !important;
height: 13px !important;

}
.option-node-target.left {
left: -10px !important;
top: 30% !important;
}

.option-node-target.right {
right: 10px !important;
top: 13px !important;
}

.list-node{
position: relative;
padding-top: 16px;
border: 1px solid grey;
border-radius: 7px;
}
.list-node span{
position: absolute;
top: 3px;
left: 3px;
font-size: 9px;
text-transform: uppercase;
font-weight: 800;
}
.list-node p{
margin: 0 !important;
white-space: nowrap;
overflow-x: auto;
font-size: 12px;
}

.react-flow__handle{
width: 13px !important;
height: 13px !important;
background: #23a6ec;

}
.react-flow__handle-right{
right : -10px !important
}
.react-flow__handle-left{
left : -10px !important;
}


/* option list editing */
.option-editor-list{
  position: relative;
}
.option-editor-list ul{
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 14px;
}
.option-editor-list li{
  min-height: 40px;
  background-color: #ffffff;
  position: relative;
}

.option-editor-list li span{
  position: absolute;
  top: 4px;
  right: 7px;
  width: 20px;
  height: 20px;
  background-color: red;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}

.option-editor-list li{
  margin-bottom: 5px;
  margin-bottom: 12px;
  padding: 7px;
  border-radius: 7px;

}

.option-editor-list li input:first-child {
  height: 15px;
  width: 15px;
  text-align: center;
  position: absolute;
  top: -7px;
}
.option-editor-list li input{
  height: 40px;
  width: calc(100%  - 10px);
}
.close-edit-modal {
  position: absolute;
  font-size: 20px;
  cursor: pointer;
  margin-top: -9px;
  right: 11px;
}
.option-editor-list li input{
  border: none;
  outline: none;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 5px;
  font-size: 12px;
}
.option-action-list{
  display: flex;
  align-items: center;
  justify-content: center;
}
.option-action-list span {
  width: 30px;
  height: 30px;
  background-color: red;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
}
/* save answer in variable */
.save-answer {
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
  height: 24px;
  background: #fff;
  padding: 9px;
  margin-top: 6px;
  border-radius: 5px;
}
.save-answer span {
  color: grey;
}
.save-answer input {
  width: 100%;
  border: none;
  outline: none;
  height: 30px;
  background: transparent;
  padding: 0 7px;
}


.green-box > .style-contentWrapper{
  background-color: #7fff7f;
  color: #00321d;
  text-transform: uppercase;
  font-size: 22px;
  display: flex;
  align-items: center;
  min-height: 30px;
}
.red-box > .style-contentWrapper{
  background-color: rgb(255, 201, 201);
  color: #4e0000;
  text-transform: uppercase;
  font-size: 22px;
  display: flex;
  align-items: center;
  min-height: 30px;
}

.green-box .node-pointer{
  background-color: #00321d !important;
}
.red-box .node-pointer{
  background-color: #4e0000 !important;
}



.condition-form>div{
  width: 100%;

}

.condition-select, .condition-input{
  height: 30px;
  margin: 0;
  margin-bottom: 10px;
}
.allchatbot-border{
  display: flex;
  width: 100%;
  gap: 1.27%;
  flex-wrap: wrap;
}

.item-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-title h3{
  opacity: 0.8;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
 
  .allchatbotbuilder-btn{
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root{
      font-size: 7px !important;
      width: 90% !important;
    }
    .css-sghohy-MuiButtonBase-root-MuiButton-root{
      font-size: 7px !important;
      width: 90% !important;
      margin-top: 4%
  }
   
  }
  .allchatbot-border{
    display: list-item;
    grid-template-columns: 100% 30% 30%;
    width: 100%;
    justify-content: space-between;
  }

  .item-title h3 {
    opacity: 0.8;
    font-weight: 500;
    font-size: 13px;
}
}

.logo-update-chatFlow {
  padding:12px;
  border-radius:6px;
  display:flex;
  align-items:center;
  flex-direction:column;
  gap:14px;
  cursor:pointer;
  border: 1px solid rgba(0,0,0, .3);
}
.flow-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  background: #10163a;
  color: #ffff;
  padding: 0 15px;
}
.style-icon{
  height: 39px;
  width: fit-content;
  text-transform: uppercase;
  border: 1px solid #ffffff;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}
.tab-container {
  :where(.css-dev-only-do-not-override-1b0bdye).ant-tabs-top >.ant-tabs-nav, :where(.css-dev-only-do-not-override-1b0bdye).ant-tabs-bottom >.ant-tabs-nav, :where(.css-dev-only-do-not-override-1b0bdye).ant-tabs-top >div>.ant-tabs-nav, :where(.css-dev-only-do-not-override-1b0bdye).ant-tabs-bottom >div>.ant-tabs-nav {
    margin: 0 !important;
  }
}

