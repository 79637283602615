.Mian-channel{
    background-color: #fff;
    width: 81%;
    overflow-y: auto;
}

.main-container{
    margin-top: 3%;
    margin-left: 13%;
}
.Whatsapp{
    margin-top: 10%;
    
}
.main-border{
    width: 379px;
    height: 197px;
    border: 1px solid rgb(194, 191, 191);
    border-radius: 10px;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.whatsapp-icon{
    float: left;
    margin-top: 16%;
    margin-left: -11%;
    opacity: .9;
}
.add-icon{
    float: right;
    color: rgb(37, 150, 190);
    margin-right: 4%;
    margin-top: 5%;
    
}
.whatspp-text{
    float: left;
    margin-top: 17%;
    margin-left: -11%;
    opacity: .7;
    font-weight: 600;
}
.heading-pra{
    float: right;
    margin-top: 3%;
    margin-left: 5%;
    opacity: .8;
}
.newChannel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4%;
}
.channel-icon {
    font-size: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.channel-container {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-between;
    gap: 20px;
}
.Add-New-Channel{
    font-size: 20px;
    font-weight: 550;
    opacity: 0.7;
}
.item-data{
    font-size: 16px;
    font-weight: 560;
    margin-top: 10px;
    opacity: 0.9;
}
.item-data-discription{
    opacity: 0.5;
    font-weight: 560;
}
@media screen and (max-width: 767px) {
    .Add-New-Channel{
        font-size: 14px;
        font-weight: 550;
        opacity: 0.7;
    }
    .item-data{
        font-size: 12px;
        font-weight: 560;
        margin-top: 10px;
        opacity: 0.9;
    }
    .item-data-discription{
        opacity: 0.5;
        font-weight: 560;
        font-size: 10px;
    }
    .newChannel{
    .css-sghohy-MuiButtonBase-root-MuiButton-root{
          font-size: 9px !important;
    }
    }
}