.Shopify-container{
  .Connection{
    font-weight: 600;
    font-size: 16px;
    margin-top: 2%;
  }
  .requireAccess{
    font-weight: 400;
    margin-top: 5px;
    width: 67%;
  }
  .Shopify-Store{
    font-size: 14px;
    font-weight: 600;
  }
  .Event-Notifications{
    font-weight: 600;
    font-size: 16px;
    margin-top: 3%;
  }
  .Event-Notifications-pre{
    font-weight: 400;
    margin-top: 5px;
    width: 67%;
  }
  .Order-Shipped{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 25px;
  }
}