.count-card-container {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 10px;

  div {
    width: 100%;
  }
}

//Progress Chart style

.progress-info-container {
  display: flex;
  width: 100%;
  text-align: center;

  div {
    width: 100%;

    .label {
      font-size: 12px;
    }
    .value {
    font-size: 18px;
      font-weight: 550;
    }
  }
}