.main-container-week{
    display: flex;
    gap: 10px;
    margin-top: 1%;
}
@media only screen and (max-width: 767px) {
    .main-container-week{
       .cards-box{
        margin-top: 1%;
        font-size: 15px !important;
       }
     
    .whatsapp{
        font-size: 15px !important;
    }
    }
  }