.deviceMessage::-webkit-scrollbar {
  display: none;
}

.deviceMessage {
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-grow: 1;
  height: 90%;
  overflow-y: auto
}